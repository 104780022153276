import AboutUsPage from "@components/about-us-page";
import { loadTranslation } from "@lib/utils";
import { GetStaticProps } from "next";
import { t } from "@lingui/macro";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translation = await loadTranslation(
    locale,
    process.env.NODE_ENV == "production"
  );

  return { props: { translation, locale } };
};

const AboutUs: React.FunctionComponent = () => {
  const router = useRouter();
  const locale = router.locale || router.defaultLocale;
  const seoLocale = "/" + locale;
  const title = t`About Us - Lumi News Malaysia`;
  const description = t`Find out more about Lumi News and the team behind Malaysia's latest news app.`;
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          url: `https://luminews.my${
            seoLocale.includes("en") ? "" : seoLocale
          }${router.asPath}`,
          title: title,
          description: description,
          images: [
            {
              url: "https://luminews.my/subdomain/about-us/about-us-seoimage.jpeg",
              alt: "About Us - Lumi News Malaysia",
            },
          ],
        }}
      />
      <AboutUsPage />
    </>
  );
};

export default AboutUs;
