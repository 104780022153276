
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_subdomain/about/about-us",
      function () {
        return require("private-next-pages/_subdomain/about/about-us.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_subdomain/about/about-us"])
      });
    }
  