import Link from "next/link";
import Image from "next/image";
import QrCodeContainer from "../core/qr-code-container";
import WhiteLogo from "@public/logo-white.png";
import BlackLogo from "@public/logo-black.png";
import DownloadNowImage from "../core/download-now-image";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import {
  SocialMediaIcons,
  generateDomainPath,
  redirectedBrowserTab,
  sharedLinks,
} from "./shared/link-props";
import { SocialProfileJsonLd } from "next-seo";

// Show gray footer if the page route matches the following routes
const routesWithGrayFooter = [
  "/about-us",
  "/careers",
  "/careers/[name]",
  "/why-use-lumi",
  "/news-stack/[name]",
  "/contact-us",
  "/publisher-application",
];

const removedLastTwoLinks = sharedLinks.slice(0, -2);
const lastTwoLinks = sharedLinks.slice(-2);

const Footer: React.FunctionComponent = () => {
  const router = useRouter();
  const { locale, pathname, asPath } = router;
  const seoLocale = "/" + locale;

  const currentYear = new Date().getFullYear();

  const isGrayFooter = routesWithGrayFooter.find((route) =>
    pathname.includes(route)
  );

  const VARIANT_MAPS = {
    ...(isGrayFooter
      ? {
          backgroundColor: "bg-light-background",
          textColor: "text-text-darkest",
          linkColor: "text-text-lightest",
          bottomLinkColor: "text-text-lightest",
          borderColor: "border-text-lightest",
          copyRightColor: "text-text-lightest",
        }
      : {
          backgroundColor: "bg-primary",
          textColor: "text-white",
          linkColor: "text-primary4",
          bottomLinkColor: "text-primary3",
          borderColor: "border-primary2",
          copyRightColor: "text-primary2",
        }),
  };

  const selectedLocale =
    (locale || router.defaultLocale) === "en" ? "" : locale;

  // To open a new tab if logo or link of footer section is clicked on subdomain pages
  const showNewTab = pathname.startsWith("/_subdomain");

  // To remove last scroll position of home if home logo is clicked
  const clearHomeSession = () => {
    sessionStorage.removeItem(`scrollPos:/`);
  };

  return (
    <footer className={`${VARIANT_MAPS.backgroundColor} px-8 pt-16 pb-20`}>
      <div className="mx-auto max-w-5xl">
        <div className="lg:flex lg:justify-between lg:space-x-8">
          <div className="flex flex-col items-center lg:flex-1 lg:items-start">
            <div onClick={clearHomeSession}>
              <Link href={generateDomainPath(`/${locale}`)}>
                <a target={showNewTab ? "_blank" : undefined}>
                  <Image
                    src={isGrayFooter ? BlackLogo : WhiteLogo}
                    height={24}
                    width={104}
                    alt="logo"
                    priority
                  />
                </a>
              </Link>
            </div>

            <div className="flex flex-col items-center sm:hidden">
              <p
                className={`mt-4 text-center font-poppins text-sm font-bold ${VARIANT_MAPS.textColor} ${VARIANT_MAPS.backgroundColor}`}
              >
                <Trans>Get the Lumi app now!</Trans>
              </p>
              <div className="mt-2">
                <DownloadNowImage
                  size="small"
                  initialUrl="https://link.luminews.my/ewC9/qrdl3"
                  locale={locale}
                  eventName={"Download Button Footer"}
                />
              </div>
            </div>

            <div className="mt-8 hidden w-80 sm:block">
              <QrCodeContainer
                size="medium"
                text={<Trans>Scan to download the Lumi News app</Trans>}
                initialUrl="https://link.luminews.my/ewC9/qrdl3"
              />
            </div>
          </div>

          <div>
            <div
              className={`mx-auto mt-8 flex items-start justify-center ${VARIANT_MAPS.linkColor}  lg:mt-0 lg:justify-start`}
            >
              <SocialMediaIcons tiktokColor={isGrayFooter ? "gray" : "blue"} />
            </div>

            <div className="mt-4 grid grid-cols-2 gap-x-10 gap-y-4 md:grid-flow-col md:grid-rows-5 md:gap-y-0">
              {removedLastTwoLinks.map((link) =>
                link.external ? (
                  <a
                    href={
                      link.withLocale ? link.href + selectedLocale : link.href
                    }
                    key={link.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p
                      className={`text-center font-sen md:mt-4 md:text-left ${VARIANT_MAPS.linkColor}`}
                    >
                      {link.title}
                    </p>
                  </a>
                ) : (
                  <div key={link.href}>
                    {redirectedBrowserTab(
                      link,
                      asPath,
                      showNewTab,
                      locale,
                      "topFooter",
                      VARIANT_MAPS.linkColor
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div
          className={`my-4 w-full border-t ${VARIANT_MAPS.borderColor} lg:my-6`}
        ></div>
        <div className="flex flex-col justify-between space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
          <div className="text-center flex flex-col lg:flex-row lg:items-center lg:space-x-3 lg:text-left">
            <span className={`font-sen text-sm ${VARIANT_MAPS.copyRightColor}`}>
              © Copyright Lumi {currentYear}
            </span>
            <span className={`font-sen mt-4 lg:mt-0 text-sm ${VARIANT_MAPS.copyRightColor}`}>
              Lumi Global Sdn Bhd (1407575-K)
            </span>
            {lastTwoLinks.map((link) => (
              <div key={link.href}>
                {redirectedBrowserTab(
                  link,
                  asPath,
                  showNewTab,
                  locale,
                  "btmFooter",
                  VARIANT_MAPS.copyRightColor
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <div
              className={`flex items-center font-sen text-sm ${VARIANT_MAPS.bottomLinkColor}`}
            >
              <EnvelopeIcon className="mr-1 h-5 w-6" aria-hidden="true" />
              <a href="mailto:hello@luminews.my">hello@luminews.my</a>
            </div>
            <div
              className={`flex items-center font-sen text-sm ${VARIANT_MAPS.bottomLinkColor}`}
            >
              <PhoneIcon className="mr-1 h-5 w-6" aria-hidden="true" />
              <a href="tel:+60378903679">03-7890 3679</a>
            </div>
          </div>
        </div>
      </div>
      <SocialProfileJsonLd
        type="Organization"
        name="Lumi News Malaysia"
        url={`https://luminews.my${seoLocale.includes("en") ? "" : seoLocale}${
          router.asPath
        }`}
        sameAs={[
          "https://www.tiktok.com/@luminews.my",
          "https://www.instagram.com/luminews.my/",
          "https://www.facebook.com/luminews.my/",
          "https://twitter.com/luminewsmy",
          "https://discord.com/invite/3Qzh6S3gJy",
        ]}
      />
    </footer>
  );
};

export default Footer;
