import Header from "@components/layout/header";
import AboutUsHero from "@public/subdomain/about-us/about-us-hero.jpeg";
import BottomGroupPhoto from "@public/subdomain/about-us/bottom-group-photo.png";
import Vector from "@public/subdomain/about-us/Vector.png";
import Confetti from "@public/subdomain/about-us/confetti.png";
import VectorTwo from "@public/subdomain/about-us/VectorTwo.png";
import VectorThree from "@public/subdomain/about-us/VectorThree.png";
import Image from "next/image";
import Fade from "react-reveal/Fade";
import Link from "next/link";
import Footer from "@components/layout/footer";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { useWindowWidth } from "@react-hook/window-size";
import { Trans } from "@lingui/macro";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  fellowPortraits,
  firstCarouselItems,
  secondCarouselItems,
} from "./about-us-details";
import "@splidejs/splide/dist/css/splide.min.css";

const AboutUsPage: React.FunctionComponent = () => {
  const width = useWindowWidth();

  const isSmallScreen = width <= 640;
  const isMedScreen = width > 640 && width <= 820;

  const splideOptions: Object = {
    type: "loop",
    drag: "free",
    perPage: 7,
    gap: "20px",
    arrows: false,
    pagination: false,
    direction: "rtl",
    autoWidth: true,
    autoScroll: {
      pauseOnHover: false,
      pauseOnFocus: false,
      rewind: false,
      speed: 1,
    },
    breakpoints: {
      640: {
        perPage: 2,
        gap: "10px",
        autoScroll: {
          speed: 0.5,
        },
      },
    },
  };

  return (
    <>
      <Header showNewsStacks={false} />
      <div className="mx-auto flex flex-1 flex-col overflow-hidden bg-off-white">
        <Fade left ssrReveal>
          <div className="mx-auto w-full max-w-[1600px]">
            <Image src={AboutUsHero} alt="Group Photo" sizes="100vw" />
          </div>
        </Fade>
        <div className="mx-auto mb-24 max-w-7xl md:mb-24 md:flex md:items-center md:justify-center">
          <Fade left delay={isSmallScreen || isMedScreen ? 1000 : 0}>
            <p className="mb-14 w-full px-10 py-10 font-poppins text-3xl font-bold md:text-3xl lg:text-4xl">
              <Trans>
                Our mission is to make news reading{" "}
                <p className="inline text-primary">enjoyable</p> for everyone.{" "}
                <br />
                <br />
                We believe that greater access to information empowers people of
                all ages and world views to make a difference - to make the
                world a better, <p className="inline text-primary">brighter </p>
                place.
              </Trans>
            </p>
          </Fade>
          <Fade right delay={isMedScreen ? 1000 : 0}>
            <video
              className="md:w-[400px] lg:w-[500px] xl:w-[700px]"
              autoPlay
              playsInline
              loop
              muted
            >
              <source src="/subdomain/about-us/About_Us.mp4" type="video/mp4" />
            </video>
          </Fade>
        </div>

        <div className="mx-auto mb-24 max-w-3xl text-center md:mb-56">
          <Fade top>
            <Image src={Confetti} alt="confetti" priority />
          </Fade>
          <Fade bottom>
            <Trans>
              <p className="px-10 py-10 font-poppins text-3xl font-bold md:text-4xl">
                We hope to bring lasting impact to Malaysians and publishers
                alike, and along the way, grow Lumi News into the{" "}
                <p className="inline text-primary">
                  only news app Malaysians need.
                </p>
              </p>
            </Trans>
          </Fade>
        </div>

        <Fade left>
          <div>
            <Splide
              options={{ ...splideOptions, direction: "rtl" }}
              extensions={{ AutoScroll }}
            >
              {firstCarouselItems.map((item, index) => (
                <SplideSlide key={index}>
                  <img
                    src={`${item.src}`}
                    alt={item.alt}
                    className="h-80 rounded-md"
                  />
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Fade>

        <Fade right>
          <div className="mb-30 mt-10 md:mb-56">
            <Splide
              options={{ ...splideOptions, direction: "ltr" }}
              extensions={{ AutoScroll }}
            >
              {secondCarouselItems.map((item, index) => (
                <SplideSlide key={index}>
                  <img
                    src={`${item.src}`}
                    alt={item.alt}
                    className="h-80 rounded-md"
                  />
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Fade>

        <div className="mt-20 px-6 md:mt-0">
          <Fade top>
            <p className="mb-4 text-center font-poppins text-3xl font-bold text-primary">
              <Trans>Our Team</Trans>
            </p>
          </Fade>
          <Fade bottom>
            <p className="text-center font-sen text-xl">
              <Trans>
                Our backgrounds in tech, media, and design make us a mighty team
                that packs a punch.
              </Trans>
            </p>
          </Fade>
        </div>
        <div className="relative mb-32 px-4">
          <Fade bottom>
            {fellowPortraits
              .reduce(function (
                accumulator,
                currentValue,
                currentIndex,
                array
              ) {
                if (currentIndex % 2 === 0)
                  accumulator.push(array.slice(currentIndex, currentIndex + 2));
                return accumulator;
              },
              [])
              .map((portrait, index) => (
                <div
                  key={index}
                  className="mt-6 hidden justify-center space-x-10 md:flex"
                >
                  <div key={portrait[0].name} className="mt-12 w-5/12 lg:w-96">
                    <div className="relative h-[480px]">
                      <Image
                        src={portrait[0].src}
                        alt={portrait[0].name}
                        layout="fill"
                        objectFit="cover"
                        priority
                        className="rounded-3xl"
                      />
                    </div>
                    <p className="mt-6 font-poppins text-2xl font-bold">
                      {portrait[0].name}
                    </p>
                    <p className="mb-2 font-poppins text-text-medium md:text-xl md:text-text-darkest">
                      {portrait[0].title}
                    </p>
                    <p className="font-sen text-text-dark">
                      {portrait[0].quote}
                    </p>
                  </div>
                  {portrait[1] && (
                    <div
                      key={portrait[1].name}
                      className="mt-12 w-5/12 lg:w-96"
                    >
                      <div className="relative h-[480px]">
                        <Image
                          src={portrait[1].src}
                          alt={portrait[1].name}
                          priority
                          layout="fill"
                          objectFit="cover"
                          className="rounded-3xl"
                        />
                      </div>
                      <p className="mt-6 font-poppins text-2xl font-bold">
                        {portrait[1].name}
                      </p>
                      <p className="mb-2 font-poppins text-text-medium md:text-xl md:text-text-darkest">
                        {portrait[1].title}
                      </p>
                      <p className="font-sen text-text-dark">
                        {portrait[1].quote}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </Fade>
          <Fade bottom>
            {fellowPortraits.map((portrait, index) => (
              <div className="mt-12 px-3 md:hidden" key={index}>
                <Image src={portrait.src} alt={portrait.name} priority />
                <p className="mt-6 font-poppins text-2xl font-bold">
                  {portrait.name}
                </p>
                <p className="mb-2 font-poppins text-text-medium md:text-xl md:text-text-darkest">
                  {portrait.title}
                </p>
                <p className="font-sen text-text-dark">{portrait.quote}</p>
              </div>
            ))}
          </Fade>
          <div className="hidden xl:absolute xl:top-0 xl:right-0 xl:block">
            <Image src={Vector} alt="vector" priority />
          </div>
          <div className="hidden xl:absolute xl:top-0 xl:left-0 xl:block">
            <Image src={VectorTwo} alt="vectorTwo" priority />
          </div>
          <div className="hidden xl:absolute xl:bottom-0 xl:right-0 xl:block">
            <Image src={VectorThree} alt="vectorThree" priority />
          </div>
        </div>
        <Fade left>
          <div className="mx-auto mb-16 w-3/4 md:mb-32">
            <Image
              src={BottomGroupPhoto}
              alt="second-group-photo-portrait"
              priority
            />
          </div>
        </Fade>
        <div className="mb-10">
          <Fade top>
            <p className="mb-16 text-center font-poppins text-3xl font-bold text-primary md:mb-28">
              <Trans>Values we love</Trans>
            </p>
          </Fade>
          <div className="mx-auto max-w-5xl px-4 lg:grid lg:grid-cols-3 lg:gap-x-4 lg:gap-y-8">
            <Fade bottom>
              <div className="mb-6 lg:mb-0">
                <p className="mb-4 text-5xl">💕</p>
                <p className="mb-4 font-poppins text-2xl font-bold">
                  <Trans>Empathy</Trans>
                </p>
                <p className="font-sen text-text-dark">
                  <Trans>
                    We design solutions for real people and problems.
                  </Trans>
                </p>
              </div>
              <div className="mb-6 lg:mb-0">
                <p className="mb-4 text-5xl">🎤</p>
                <p className="mb-4 font-poppins text-2xl font-bold">
                  <Trans>Inclusion</Trans>
                </p>
                <p className="font-sen text-text-dark">
                  <Trans>
                    Everyone’s aboard in the product creation process.
                  </Trans>
                </p>
              </div>
              <div className="mb-6 lg:mb-0">
                <p className="mb-4 text-5xl">🤝</p>
                <p className="mb-4 font-poppins text-2xl font-bold">
                  <Trans>Camaraderie</Trans>
                </p>
                <p className="font-sen text-text-dark">
                  <Trans>Teamwork makes the dream work. Badminton too.</Trans>
                </p>
              </div>
            </Fade>
            <Fade bottom>
              <div className="mb-6 lg:mb-0">
                <p className="mb-4 text-5xl">🔥</p>
                <p className="mb-4 font-poppins text-2xl font-bold">
                  <Trans>High ownership</Trans>
                </p>
                <p className="font-sen text-text-dark">
                  <Trans>
                    We find meaning in what we do and that inspires us to put
                    out our best work.
                  </Trans>
                </p>
              </div>
              <div className="mb-6 lg:mb-0">
                <p className="mb-4 text-5xl">✨</p>
                <p className="mb-4 font-poppins text-2xl font-bold">
                  <Trans>Openness</Trans>
                </p>
                <p className="font-sen text-text-dark">
                  <Trans>
                    We love to turn the mundane into the extraordinary.
                  </Trans>
                </p>
              </div>
              <div className="mb-6 lg:mb-0">
                <p className="mb-4 text-5xl">🌏</p>
                <p className="mb-4 font-poppins text-2xl font-bold">
                  <Trans>Down-to-earth</Trans>
                </p>
                <p className="font-sen text-text-dark">
                  <Trans>
                    To be approachable means communication is easier.
                  </Trans>
                </p>
              </div>
            </Fade>
          </div>
        </div>
        <div className="relative mt-20">
          <Fade bottom>
            <p className="mt-10 mb-10 text-center font-poppins text-3xl font-bold text-primary">
              <Trans>Join Us!</Trans>
            </p>
            <div className="mx-auto mb-52 w-80 text-center md:w-full">
              <Trans>
                <p className="mb-10 font-sen text-text-dark">
                  Our team is looking to hire more builders and creators! <br />
                  Check out the openings available or contact us at{" "}
                  <a
                    href="mailto: work@luminews.my"
                    className="text-primary underline"
                  >
                    talent@luminews.my
                  </a>
                </p>
              </Trans>
              <Link href="/careers">
                <a className="inline rounded-lg bg-primary px-10 py-4 font-poppins text-white">
                  <Trans>View all openings</Trans>
                </a>
              </Link>
            </div>
          </Fade>
          <div className="hidden xl:absolute xl:top-0 xl:left-0 xl:block">
            <Image
              src={VectorTwo}
              alt="vectorTwo"
              height={300}
              width={100}
              priority
            />
          </div>
          <div className="hidden xl:absolute xl:top-10 xl:right-0 xl:block">
            <Image
              src={VectorThree}
              alt="vectorThree"
              height={300}
              width={100}
              priority
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUsPage;
